// Male
var kemperData = [{
    "male": [{
            "Issue Age": 40,
            "2000": "$18.07",
            "3000": "$25.02",
            "4000": "$31.97",
            "5000": "$38.93",
            "7500": "$56.51",
            "10000": "$73.37",
            "150000": "$107.82",
            "200000": "$142.26",
            "250000": "$176.72"
        },
        {
            "Issue Age": 41,
            "2000": "$18.33",
            "3000": "$25.42",
            "4000": "$32.50",
            "5000": "$38.93",
            "7500": "$57.50",
            "10000": "$74.72",
            "150000": "$109.82",
            "200000": "$144.95",
            "250000": "$180.05"
        },
        {
            "Issue Age": 42,
            "2000": "$18.60",
            "3000": "$25.82",
            "4000": "$33.04",
            "5000": "$39.59",
            "7500": "$58.50",
            "10000": "$76.05",
            "150000": "$111.84",
            "200000": "$147.62",
            "250000": "$183.41"
        },
        {
            "Issue Age": 43,
            "2000": "$18.87",
            "3000": "$26.23",
            "4000": "$33.58",
            "5000": "$40.26",
            "7500": "$59.52",
            "10000": "$77.37",
            "150000": "$113.82",
            "200000": "$150.27",
            "250000": "$186.72"
        },
        {
            "Issue Age": 44,
            "2000": "$19.14",
            "3000": "$26.62",
            "4000": "$34.11",
            "5000": "$40.94",
            "7500": "$60.53",
            "10000": "$78.71",
            "150000": "$115.83",
            "200000": "$152.94",
            "250000": "$190.07"
        },
        {
            "Issue Age": 45,
            "2000": "$19.40",
            "3000": "$27.02",
            "4000": "$34.64",
            "5000": "$41.60",
            "7500": "$61.53",
            "10000": "$80.06",
            "150000": "$117.83",
            "200000": "$155.60",
            "250000": "$193.40"
        },
        {
            "Issue Age": 46,
            "2000": "$19.62",
            "3000": "$27.34",
            "4000": "$35.07",
            "5000": "$42.26",
            "7500": "$62.63",
            "10000": "$81.14",
            "150000": "$119.46",
            "200000": "$157.79",
            "250000": "$196.11"
        },
        {
            "Issue Age": 47,
            "2000": "$19.82",
            "3000": "$27.64",
            "4000": "$35.47",
            "5000": "$42.80",
            "7500": "$63.11",
            "10000": "$82.13",
            "150000": "$120.96",
            "200000": "$159.77",
            "250000": "$198.60"
        },
        {
            "Issue Age": 48,
            "2000": "$20.24",
            "3000": "$28.27",
            "4000": "$36.31",
            "5000": "$43.29",
            "7500": "$64.67",
            "10000": "$84.23",
            "150000": "$124.07",
            "200000": "$163.94",
            "250000": "$203.82"
        },
        {
            "Issue Age": 49,
            "2000": "$20.40",
            "3000": "$28.51",
            "4000": "$36.63",
            "5000": "$43.34",
            "7500": "$65.28",
            "10000": "$85.01",
            "150000": "$125.28",
            "200000": "$165.54",
            "250000": "$1205.82"
        },
        {
            "Issue Age": 50,
            "2000": "$20.54",
            "3000": "$28.73",
            "4000": "$36.92",
            "5000": "$44.75",
            "7500": "$65.84",
            "10000": "$85.74",
            "150000": "$126.38",
            "200000": "$167.00",
            "250000": "$207.62"
        }, {
            "Issue Age": 51,
            "2000": "$21.06",
            "3000": "$29.51",
            "4000": "$37.96",
            "5000": "$46.41",
            "7500": "$67.80",
            "10000": "$88.35",
            "150000": "$130.28",
            "200000": "$172.22",
            "250000": "$214.14"
        },
        {
            "Issue Age": 52,
            "2000": "$21.56",
            "3000": "$30.25",
            "4000": "$38.95",
            "5000": "$47.64",
            "7500": "$69.65",
            "10000": "$90.78",
            "150000": "$133.95",
            "200000": "$177.11",
            "250000": "$220.25"
        },
        {
            "Issue Age": 53,
            "2000": "$22.01",
            "3000": "$30.93",
            "4000": "$39.85",
            "5000": "$48.77",
            "7500": "$71.36",
            "10000": "$93.08",
            "150000": "$137.37",
            "200000": "$181.67",
            "250000": "$225.96"
        },
        {
            "Issue Age": 54,
            "2000": "$22.43",
            "3000": "$31.56",
            "4000": "$40.70",
            "5000": "$49.83",
            "7500": "$72.96",
            "10000": "$95.19",
            "150000": "$140.55",
            "200000": "$185.90",
            "250000": "$231.26"
        },
        {
            "Issue Age": 55,
            "2000": "$23.02",
            "3000": "$32.45",
            "4000": "$41.87",
            "5000": "$51.30",
            "7500": "$75.18",
            "10000": "$98.09",
            "150000": "$144.84",
            "200000": "$191.64",
            "250000": "$238.41"
        },
        {
            "Issue Age": 56,
            "2000": "$23.48",
            "3000": "$33.13",
            "4000": "$42.79",
            "5000": "$52.45",
            "7500": "$76.86",
            "10000": "$100.31",
            "150000": "$148.16",
            "200000": "$196.02",
            "250000": "$243.87"
        },
        {
            "Issue Age": 57,
            "2000": "$23.73",
            "3000": "$33.51",
            "4000": "$43.29",
            "5000": "$53.08",
            "7500": "$77.77",
            "10000": "$101.51",
            "150000": "$149.96",
            "200000": "$198.39",
            "250000": "$246.83"
        },
        {
            "Issue Age": 58,
            "2000": "$24.48",
            "3000": "$34.64",
            "4000": "$44.79",
            "5000": "$64.95",
            "7500": "$80.55",
            "10000": "$105.14",
            "150000": "$155.39",
            "200000": "$205.63",
            "250000": "$255.83"
        },
        {
            "Issue Age": 59,
            "2000": "$25.04",
            "3000": "$35.48",
            "4000": "$45.92",
            "5000": "$56.36",
            "7500": "$82.68",
            "10000": "$107.98",
            "150000": "$159.58",
            "200000": "$211.19",
            "250000": "$262.79"
        },
        {
            "Issue Age": 60,
            "2000": "$25.73",
            "3000": "$36.52",
            "4000": "$47.30",
            "5000": "$58.08",
            "7500": "$85.24",
            "10000": "$111.36",
            "150000": "$164.62",
            "200000": "$217.88",
            "250000": "$271.15"
        },
        {
            "Issue Age": 61,
            "2000": "$26.40",
            "3000": "$37.52",
            "4000": "$48.64",
            "5000": "$59.76",
            "7500": "$87.76",
            "10000": "$114.67",
            "150000": "$169.56",
            "200000": "$224.46",
            "250000": "$279.36"
        },
        {
            "Issue Age": 62,
            "2000": "$27.09",
            "3000": "$38.55",
            "4000": "$50.01",
            "5000": "$61.47",
            "7500": "$90.31",
            "10000": "$117.99",
            "150000": "$174.51",
            "200000": "$231.07",
            "250000": "$287.59"
        }, {
            "Issue Age": 63,
            "2000": "$27.93",
            "3000": "$39.81",
            "4000": "$51.70",
            "5000": "$63.58",
            "7500": "$93.43",
            "10000": "$122.14",
            "150000": "$180.67",
            "200000": "$239.21",
            "250000": "$297.76"
        },
        {
            "Issue Age": 64,
            "2000": "$28.73",
            "3000": "$41.01",
            "4000": "$53.29",
            "5000": "$65.57",
            "7500": "$96.41",
            "10000": "$126.03",
            "150000": "$186.47",
            "200000": "$246.92",
            "250000": "$307.36"
        },
        {
            "Issue Age": 65,
            "2000": "$29.48",
            "3000": "$42.13",
            "4000": "$54.79",
            "5000": "$67.45",
            "7500": "$99.18",
            "10000": "$129.68",
            "150000": "$191.92",
            "200000": "$254.17",
            "250000": "$316.41"
        },
        {
            "Issue Age": 66,
            "2000": "$30.38",
            "3000": "$43.49",
            "4000": "$56.60",
            "5000": "$69.70",
            "7500": "$102.60",
            "10000": "$134.23",
            "150000": "$198.76",
            "200000": "$263.29",
            "250000": "$327.83"
        },
        {
            "Issue Age": 67,
            "2000": "$31.12",
            "3000": "$44.59",
            "4000": "$58.06",
            "5000": "$71.54",
            "7500": "$105.41",
            "10000": "$137.94",
            "150000": "$204.33",
            "200000": "$270.73",
            "250000": "$337.14"
        },
        {
            "Issue Age": 68,
            "2000": "$31.75",
            "3000": "$45.55",
            "4000": "$59.34",
            "5000": "$73.13",
            "7500": "$107.80",
            "10000": "$141.14",
            "150000": "$209.15",
            "200000": "$277.17",
            "250000": "$345.18"
        },
        {
            "Issue Age": 69,
            "2000": "$32.27",
            "3000": "$46.33",
            "4000": "$60.38",
            "5000": "$74.43",
            "7500": "$109.80",
            "10000": "$143.77",
            "150000": "$213.11",
            "200000": "$282.47",
            "250000": "$351.80"
        },
        {
            "Issue Age": 70,
            "2000": "$32.69",
            "3000": "$46.95",
            "4000": "$61.21",
            "5000": "$75.48",
            "7500": "$111.39",
            "10000": "$145.90",
            "150000": "$216.33",
            "200000": "$286.76",
            "250000": "$357.19"
        },
        {
            "Issue Age": 71,
            "2000": "$33.12",
            "3000": "$47.59",
            "4000": "$62.06",
            "5000": "$76.54",
            "7500": "$113.02",
            "10000": "$148.06",
            "150000": "$219.57",
            "200000": "$291.10",
            "250000": "$362.63"
        },
        {
            "Issue Age": 72,
            "2000": "$33.70",
            "3000": "$48.47",
            "4000": "$63.24",
            "5000": "$78.00",
            "7500": "$115.25",
            "10000": "$151.03",
            "150000": "$224.06",
            "200000": "$297.08",
            "250000": "$370.11"
        },
        {
            "Issue Age": 73,
            "2000": "$34.85",
            "3000": "$50.19",
            "4000": "$65.53",
            "5000": "$80.87",
            "7500": "$119.57",
            "10000": "$156.76",
            "150000": "$232.65",
            "200000": "$308.55",
            "250000": "$384.44"
        },
        {
            "Issue Age": 74,
            "2000": "$35.94",
            "3000": "$51.83",
            "4000": "$67.72",
            "5000": "$83.61",
            "7500": "$123.72",
            "10000": "$162.25",
            "150000": "$240.90",
            "200000": "$319.54",
            "250000": "$398.18"
        },
        {
            "Issue Age": 75,
            "2000": "$36.91",
            "3000": "$53.28",
            "4000": "$69.65",
            "5000": "$86.02",
            "7500": "$127.36",
            "10000": "$167.06",
            "150000": "$248.09",
            "200000": "$329.13",
            "250000": "$410.16"
        },
        {
            "Issue Age": 76,
            "2000": "$38.38",
            "3000": "$55.48",
            "4000": "$72.58",
            "5000": "$89.69",
            "7500": "$132.88",
            "10000": "$174.37",
            "150000": "$259.08",
            "200000": "$343.76",
            "250000": "$428.45"
        },
        {
            "Issue Age": 77,
            "2000": "$41.23",
            "3000": "$59.76",
            "4000": "$78.29",
            "5000": "$96.82",
            "7500": "$143.62",
            "10000": "$188.59",
            "150000": "$280.36",
            "200000": "$372.14",
            "250000": "$463.93"
        },
        {
            "Issue Age": 78,
            "2000": "$44.11",
            "3000": "$64.08",
            "4000": "$84.05",
            "5000": "$104.02",
            "7500": "$154.49",
            "10000": "$202.97",
            "150000": "$301.93",
            "200000": "$400.88",
            "250000": "$499.83"
        },
        {
            "Issue Age": 79,
            "2000": "$46.98",
            "3000": "$68.39",
            "4000": "$89.80",
            "5000": "$111.21",
            "7500": "$165.31",
            "10000": "$217.31",
            "150000": "$323.41",
            "200000": "$429.49",
            "250000": "$535.57"
        },
        {
            "Issue Age": 80,
            "2000": "$49.89",
            "3000": "$72.75",
            "4000": "$95.62",
            "5000": "$118.48",
            "7500": "$176.28",
            "10000": "$231.80",
            "150000": "$345.13",
            "200000": "$458.46",
            "250000": "$571.79"
        }
    ],
    "female": [{
            "Issue Age": 40,
            "2000": "$14.77",
            "3000": "$20.07",
            "4000": "$25.37",
            "5000": "$30.67",
            "7500": "$43.79",
            "10000": "$56.92",
            "150000": "$83.13",
            "200000": "$109.36",
            "250000": "$135.59"
        },
        {
            "Issue Age": 41,
            "2000": "$15.20",
            "3000": "$20.72",
            "4000": "$26.24",
            "5000": "$31.76",
            "7500": "$45.41",
            "10000": "$59.05",
            "150000": "$86.35",
            "200000": "$113.66",
            "250000": "$140.97"
        }, {
            "Issue Age": 42,
            "2000": "$15.56",
            "3000": "$21.26",
            "4000": "$26.96",
            "5000": "$32.66",
            "7500": "$46.77",
            "10000": "$60.86",
            "150000": "$89.05",
            "200000": "$117.25",
            "250000": "$145.46"
        }, {
            "Issue Age": 43,
            "2000": "$15.93",
            "3000": "$21.81",
            "4000": "$27.68",
            "5000": "$33.56",
            "7500": "$48.12",
            "10000": "$62.68",
            "150000": "$91.80",
            "200000": "$120.91",
            "250000": "$150.02"
        }, {
            "Issue Age": 44,
            "2000": "$16.30",
            "3000": "$22.37",
            "4000": "$28.43",
            "5000": "$34.50",
            "7500": "$49.53",
            "10000": "$64.55",
            "150000": "$94.59",
            "200000": "$124.63",
            "250000": "$154.67"
        }, {
            "Issue Age": 45,
            "2000": "$16.76",
            "3000": "$23.06",
            "4000": "$29.36",
            "5000": "$35.66",
            "7500": "$51.24",
            "10000": "$66.84",
            "150000": "$98.03",
            "200000": "$129.21",
            "250000": "$160.39"
        }, {
            "Issue Age": 46,
            "2000": "$17.14",
            "3000": "$23.63",
            "4000": "$30.11",
            "5000": "$36.60",
            "7500": "$52.66",
            "10000": "$68.73",
            "150000": "$100.85",
            "200000": "$132.96",
            "250000": "$165.09"
        }, {
            "Issue Age": 47,
            "2000": "$17.50",
            "3000": "$24.16",
            "4000": "$30.82",
            "5000": "$37.49",
            "7500": "$54.00",
            "10000": "$70.54",
            "150000": "$103.54",
            "200000": "$136.57",
            "250000": "$169.61"
        }, {
            "Issue Age": 48,
            "2000": "$18.00",
            "3000": "$24.91",
            "4000": "$31.82",
            "5000": "$38.74",
            "7500": "$55.88",
            "10000": "$73.02",
            "150000": "$107.28",
            "200000": "$141.55",
            "250000": "$175.80"
        }, {
            "Issue Age": 49,
            "2000": "$18.33",
            "3000": "$25.41",
            "4000": "$32.49",
            "5000": "$39.57",
            "7500": "$57.11",
            "10000": "$74.66",
            "150000": "$109.76",
            "200000": "$144.83",
            "250000": "$179.93"
        }, {
            "Issue Age": 50,
            "2000": "$18.54",
            "3000": "$25.73",
            "4000": "$32.92",
            "5000": "$40.11",
            "7500": "$57.95",
            "10000": "$75.74",
            "150000": "$111.39",
            "200000": "$147.02",
            "250000": "$182.64"
        }, {
            "Issue Age": 51,
            "2000": "$18.93",
            "3000": "$26.32",
            "4000": "$33.70",
            "5000": "$41.09",
            "7500": "$59.39",
            "10000": "$77.69",
            "150000": "$114.29",
            "200000": "$150.90",
            "250000": "$187.50"
        }, {
            "Issue Age": 52,
            "2000": "$19.30",
            "3000": "$26.87",
            "4000": "$34.43",
            "5000": "$42.00",
            "7500": "$60.74",
            "10000": "$79.50",
            "150000": "$117.00",
            "200000": "$154.52",
            "250000": "$192.03"
        }, {
            "Issue Age": 53,
            "2000": "$19.86",
            "3000": "$27.70",
            "4000": "$35.55",
            "5000": "$43.40",
            "7500": "$62.85",
            "10000": "$82.31",
            "150000": "$121.22",
            "200000": "$160.14",
            "250000": "$199.07"
        }, {
            "Issue Age": 54,
            "2000": "$20.17",
            "3000": "$28.17",
            "4000": "$36.17",
            "5000": "$44.18",
            "7500": "$64.01",
            "10000": "$83.85",
            "150000": "$123.53",
            "200000": "$163.22",
            "250000": "$202.89"
        }, {
            "Issue Age": 55,
            "2000": "$20.34",
            "3000": "$28.43",
            "4000": "$36.52",
            "5000": "$44.61",
            "7500": "$64.67",
            "10000": "$84.74",
            "150000": "$124.88",
            "200000": "$165.00",
            "250000": "$205.13"
        }, {
            "Issue Age": 56,
            "2000": "$20.34",
            "3000": "$28.42",
            "4000": "$36.51",
            "5000": "$44.59",
            "7500": "$64.64",
            "10000": "$84.68",
            "150000": "$124.82",
            "200000": "$164.90",
            "250000": "$205.02"
        }, {
            "Issue Age": 57,
            "2000": "$20.48",
            "3000": "$28.64",
            "4000": "$36.80",
            "5000": "$44.96",
            "7500": "$65.21",
            "10000": "$85.44",
            "150000": "$125.95",
            "200000": "$166.45",
            "250000": "$206.94"
        }, {
            "Issue Age": 58,
            "2000": "$21.06",
            "3000": "$29.50",
            "4000": "$37.94",
            "5000": "$46.39",
            "7500": "$67.36",
            "10000": "$88.32",
            "150000": "$130.25",
            "200000": "$172.17",
            "250000": "$214.10"
        }, {
            "Issue Age": 59,
            "2000": "$21.60",
            "3000": "$30.32",
            "4000": "$39.04",
            "5000": "$47.75",
            "7500": "$69.41",
            "10000": "$91.05",
            "150000": "$134.34",
            "200000": "$177.65",
            "250000": "$220.93"
        }, {
            "Issue Age": 60,
            "2000": "$22.07",
            "3000": "$31.02",
            "4000": "$39.98",
            "5000": "$48.93",
            "7500": "$71.12",
            "10000": "$93.31",
            "150000": "$137.69",
            "200000": "$182.10",
            "250000": "$226.50"
        }, {
            "Issue Age": 61,
            "2000": "$22.68",
            "3000": "$31.94",
            "4000": "$41.20",
            "5000": "$50.46",
            "7500": "$73.38",
            "10000": "$96.31",
            "150000": "$142.18",
            "200000": "$188.06",
            "250000": "$233.94"
        }, {
            "Issue Age": 62,
            "2000": "$23.25",
            "3000": "$32.79",
            "4000": "$42.34",
            "5000": "$51.88",
            "7500": "$75.49",
            "10000": "$99.12",
            "150000": "$146.37",
            "200000": "$193.62",
            "250000": "$240.88"
        }, {
            "Issue Age": 63,
            "2000": "$23.96",
            "3000": "$33.85",
            "4000": "$43.75",
            "5000": "$53.65",
            "7500": "$78.13",
            "10000": "$102.62",
            "150000": "$151.59",
            "200000": "$200.57",
            "250000": "$249.54"
        }, {
            "Issue Age": 64,
            "2000": "$24.62",
            "3000": "$34.85",
            "4000": "$45.08",
            "5000": "$55.31",
            "7500": "$80.59",
            "10000": "$105.88",
            "150000": "$156.47",
            "200000": "$207.05",
            "250000": "$257.62"
        }, {
            "Issue Age": 65,
            "2000": "$25.28",
            "3000": "$35.85",
            "4000": "$46.40",
            "5000": "$56.96",
            "7500": "$83.07",
            "10000": "$109.17",
            "150000": "$161.37",
            "200000": "$213.58",
            "250000": "$265.76"
        }, {
            "Issue Age": 66,
            "2000": "$25.93",
            "3000": "$36.82",
            "4000": "$47.70",
            "5000": "$58.58",
            "7500": "$85.48",
            "10000": "$112.37",
            "150000": "$166.15",
            "200000": "$219.96",
            "250000": "$273.76"
        }, {
            "Issue Age": 67,
            "2000": "$26.64",
            "3000": "$37.88",
            "4000": "$49.12",
            "5000": "$60.36",
            "7500": "$88.16",
            "10000": "$115.95",
            "150000": "$171.51",
            "200000": "$227.07",
            "250000": "$282.63"
        }, {
            "Issue Age": 68,
            "2000": "$27.23",
            "3000": "$38.77",
            "4000": "$50.30",
            "5000": "$61.83",
            "7500": "$90.37",
            "10000": "$118.87",
            "150000": "$175.88",
            "200000": "$232.89",
            "250000": "$289.90"
        }, {
            "Issue Age": 69,
            "2000": "$27.77",
            "3000": "$39.58",
            "4000": "$51.38",
            "5000": "$63.18",
            "7500": "$92.35",
            "10000": "$121.51",
            "150000": "$179.83",
            "200000": "$238.16",
            "250000": "$296.50"
        }, {
            "Issue Age": 70,
            "2000": "$28.47",
            "3000": "$40.63",
            "4000": "$52.78",
            "5000": "$64.94",
            "7500": "$94.96",
            "10000": "$124.98",
            "150000": "$185.04",
            "200000": "$245.09",
            "250000": "$305.14"
        }, {
            "Issue Age": 71,
            "2000": "$29.01",
            "3000": "$41.43",
            "4000": "$53.85",
            "5000": "$66.27",
            "7500": "$96.94",
            "10000": "$127.63",
            "150000": "$188.99",
            "200000": "$250.35",
            "250000": "$311.72"
        }, {
            "Issue Age": 72,
            "2000": "$29.69",
            "3000": "$42.45",
            "4000": "$55.21",
            "5000": "$67.97",
            "7500": "$99.50",
            "10000": "$131.02",
            "150000": "$194.07",
            "200000": "$257.12",
            "250000": "$320.18"
        }, {
            "Issue Age": 73,
            "2000": "$30.72",
            "3000": "$44.00",
            "4000": "$57.28",
            "5000": "$70.55",
            "7500": "$103.37",
            "10000": "$136.17",
            "150000": "$201.78",
            "200000": "$267.40",
            "250000": "$333.01"
        }, {
            "Issue Age": 74,
            "2000": "$31.70",
            "3000": "$45.46",
            "4000": "$59.23",
            "5000": "$72.99",
            "7500": "$107.02",
            "10000": "$141.05",
            "150000": "$209.07",
            "200000": "$277.11",
            "250000": "$345.14"
        }, {
            "Issue Age": 75,
            "2000": "$32.65",
            "3000": "$46.90",
            "4000": "$61.14",
            "5000": "$75.38",
            "7500": "$110.58",
            "10000": "$145.80",
            "150000": "$216.22",
            "200000": "$286.63",
            "250000": "$357.05"
        }, {
            "Issue Age": 76,
            "2000": "$33.55",
            "3000": "$48.24",
            "4000": "$62.94",
            "5000": "$77.63",
            "7500": "$113.99",
            "10000": "$150.34",
            "150000": "$223.05",
            "200000": "$295.76",
            "250000": "$368.48"
        }, {
            "Issue Age": 77,
            "2000": "$35.66",
            "3000": "$51.41",
            "4000": "$67.16",
            "5000": "$82.91",
            "7500": "$121.90",
            "10000": "$160.89",
            "150000": "$238.87",
            "200000": "$316.86",
            "250000": "$394.84"
        }, {
            "Issue Age": 78,
            "2000": "$37.99",
            "3000": "$54.90",
            "4000": "$71.81",
            "5000": "$88.72",
            "7500": "$130.62",
            "10000": "$172.52",
            "150000": "$256.33",
            "200000": "$340.13",
            "250000": "$423.94"
        }, {
            "Issue Age": 79,
            "2000": "$40.19",
            "3000": "$58.21",
            "4000": "$76.22",
            "5000": "$94.23",
            "7500": "$138.89",
            "10000": "$183.55",
            "150000": "$272.86",
            "200000": "$362.17",
            "250000": "$451.50"
        }, {
            "Issue Age": 80,
            "2000": "$42.71",
            "3000": "$61.99",
            "4000": "$81.26",
            "5000": "$100.53",
            "7500": "$148.33",
            "10000": "$196.13",
            "150000": "$291.75",
            "200000": "$387.36",
            "250000": "$482.98"
        }
    ]
}];