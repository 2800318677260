/** 
 * @author Adam Lewis
 * @description A validation controller for the contact section.
 */
app.controller("validation", function ($scope, isValid, leadFactory, $http, $filter) {

    /** @author Adam Lewis
     * @description Iterates through all the inputs within the form that are current required and returns bool to nextStep service. */
    $scope.validate = function () {

        // Validation against form submission

        for (var key in $scope.lead) {

            if (typeof $scope.lead[key] === 'object' && $scope.lead[key].hasOwnProperty('$modelValue')) {
                // console.log($scope.lead[key]);
                // console.log("Checking: " + $scope.lead[key].$name);
                // console.log("Invalid: " + $scope.lead[key].$invalid);

                if ($scope.lead[key].$invalid) {
                    $scope.lead[key].hasError = true;
                    isValid.setValidation(false);
                }

                if ($scope.lead.$valid && $scope.lead.$dirty) { // Confirms the lead form is valid.

                    $scope.lead[key].hasError = false;
                    isValid.setValidation(true);
                }

            }

        }

        console.log(JSON.stringify(leadFactory.getLeadData()));

        console.log($scope.$parent.$parent.phone);

		
		$http({
                method: 'POST',
                url: 'http://insurancewishcenapi.ds.trustsourcing.com/api/Generate/CreateEmbeddedSigningView',
                data: JSON.stringify(leadFactory.getLeadData()),
            }).then(function (response) {
				 console.log(response);
				 window.open(response.data.url,'_blank');
                 //alert("Success, data being passed: " + response.data.url);
                },
                function (error) {
                    alert("Error: " + error.message + " Error Data: " + error.data);
                });
			
		
    }

    $scope.checkAddress = function(address) {

        console.log($filter('json')(address));
        console.log($scope.lead.address.$viewValue);

        // Testing: https://wwwcie.ups.com/rest/AV

        // Production: https://onlinetools.ups.com/rest/AV

        $http({
            method: 'POST',
            url: 'https://wwwcie.ups.com/rest/XAV',
            data: '',
            headers: { 'Content-Type': 'application/xml'}
        })
        .then(function (response) {
            
            if(response.data.Error) {
                alert(response.data.Error.Code + " : " + response.data.Error.Description);
            }
        })
        .catch(function(error) {

            alert(error);
        })
    }

    $scope.checkEmail = function (email) {

        console.log(email);

        $http.get("https://api.mailgun.net/v3/address/validate?api_key=pubkey-330c424f83430df5ee50385ea9674799&address=" + email.$viewValue)
            .then(function (response) {
                if (!response.data.is_valid) {
                    email.hasError = true;
                } else {
                    email.hasError = false;
                    email.$valid = true;
                }
            });
    }
});