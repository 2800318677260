"use strict";

// TODO: Data.Controller.js needs to be refactored.
app.controller("dataController", function ($scope, priceCalcService) {

    // Defaults
    $scope.amount = 5000;
    $scope.message = "We'll start by asking some basic questions about you.";
    $scope.sidebarMessage = "A little about you.";

    $scope.months = [{name: "January", value: 1}, {name: "February", value: 2}, {name: "March", value: 3}, {name: "April", value: 4}, {name: "May", value: 5}, {name: "June", value: 6}, {name: "July", value: 7}, {name: "August", value: 8}, {name: "September", value: 9}, {name: "October", value: 10}, {name: "November", value: 11}, {name: "December", value: 12}];
    $scope.states = ['Select A State ', 'Alaska ', 'Alabama ', 'Arkansas ', 'Arizona ', 'California ', 'Colorado ', 'Connecticut ', 'District of Columbia ', 'Delaware ', 'Florida ', 'Georgia ', 'Hawaii ', 'Iowa ', 'Idaho ', 'Illinois ', 'Indiana ', 'Kansas ', 'Kentucky ', 'Louisiana ', 'Massachusetts ', 'Maryland ', 'Maine ', 'Michigan ', 'Minnesota ', 'Missouri ', 'Mississippi ', 'Montana ', 'North Carolina ', ' North Dakota ', 'Nebraska ', 'New Hampshire ', 'New Jersey ', 'New Mexico ', 'Nevada ', 'New York ', 'Ohio ', 'Oklahoma ', 'Oregon ', 'Pennsylvania ', 'Rhode Island ', 'South Carolina ', 'South Dakota ', 'Tennessee ', 'Texas ', 'Utah ', 'Virginia ', 'Virgin Islands ', 'Vermont ', 'Washington ', 'Wisconsin ', 'West Virginia ', 'Wyoming '];

    // Defaults for the dropdowns.
    $scope.state = $scope.states[0];
    $scope.other_person_state = $scope.states[0];

    $scope.buttonType = "button";
    $scope.currentStep = "Next Step";

    calculateRate = function (ageOfInsured, amount, gender) {

        //TODO: Refactor this, it's kind of ugly. 
        if (priceCalcService.calculateRate(ageOfInsured, amount, gender).name[2] === "columbian") {
            $scope.columbianPrice = priceCalcService.calculateRate(ageOfInsured, amount, gender).amount[2];
        } else {
            $scope.columbianPrice = 'Do not qualify';
        }

        if (priceCalcService.calculateRate(ageOfInsured, amount, gender).name[0] === "kemper") {
            $scope.kemperPrice = priceCalcService.calculateRate(ageOfInsured, amount, gender).amount[0];
        } else {
            $scope.kemperPrice = 'Do not qualify';
        }

        if (priceCalcService.calculateRate(ageOfInsured, amount, gender).name[1] === "gerber") {
            $scope.gerberPrice = priceCalcService.calculateRate(ageOfInsured, amount, gender).amount[1];
        } else {
            $scope.gerberPrice = 'Do not qualify';
        }
    }

    $scope.ageSliding = function (ageOfInsured) {
        $scope.ageOfInsured = ageOfInsured;
        calculateRate($scope.ageOfInsured, $scope.amount, $scope.gender);
    }

    $scope.amountSliding = function (amount) {
        $scope.amount = amount;
        // console.log($scope.amount);
        calculateRate($scope.ageOfInsured, $scope.amount, $scope.gender);
    }

});