
app.controller("kemperController", function ($scope) {

    // Defaults.
    $scope.companyName = "Kemper";
    $scope.sidebarMessage = $scope.companyName + " Application";
    $scope.message = "Thank you for choosing " + $scope.companyName + " Life Insurance!";
    
    // Don't make anything clickable until the form is done being filled out.
    // let submitBtn = angular.element(document.querySelector('#submitButton'));
    // submitBtn.removeClass('btn-warning');
    // submitBtn.removeClass('btn');
    // submitBtn.addClass('inactive');

    // Payment Mode values.
    $scope.paymentMode = ["Annual", "Semi-Annual", "Quarterly", "Monthly EFT", "Monthly (Debit Collection)"];
    $scope.paymentSelection = $scope.paymentMode[0];
});