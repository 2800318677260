app.directive("money",function ($filter, $locale) {
    return {
      require: 'ngModel',
      link: function (scope, el, attr, ctrl) {
        // format on init
        formatMoney();
  
        function formatMoney() {
          var value = ctrl.$modelValue;
  
          // remove all separaters first
          var groupsep = $locale.NUMBER_FORMATS.GROUP_SEP;
          var re = new RegExp(groupsep, 'g');
          value = String(value).replace(re, '');
  
          // format using angular
          var currencyFilter = $filter('currency');
          var value = currencyFilter(value, "");
  
          // sorry but no cents
          var decimalsep = $locale.NUMBER_FORMATS.DECIMAL_SEP;
          value = value.split(decimalsep)[0];

          // render
          ctrl.$viewValue = value;
          ctrl.$render();
        };
  
        // subscribe on changes
        scope.$watch(attr.ngModel, function() {
          formatMoney();
        });
      }
    };
  });


  // TODO: Update money directive to allow for USD symbol.