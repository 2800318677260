app.controller("columbianController", function ($scope) {
    /**
     * Represents a book.
     * @constructor
     */
    // Defaults.
    $scope.companyName = "Columbian";
    $scope.sidebarMessage = $scope.companyName + " Application";
    $scope.message = "Thank you for choosing " + $scope.companyName + " Life Insurance!";
    $scope.showOtherAddress = false;

    // Payment Mode values.
    $scope.paymentModes = ["Annual", "Semi-Annual", "Quarterly", "Monthly EFT", "Monthly (Debit Collection)"];

    $scope.notifyOther = function(displayOtherAddress) {

        if(displayOtherAddress) {
            $scope.showOtherAddress = true;
        } else {
            $scope.showOtherAddress = false;
        }
    }
});