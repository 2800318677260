// Male
var columbianData = [{
    "quoteFrom": "columbian",
    "male": [{
            "Issue Age": 25,
            "5000": "$23.06",
            "10000": "$42.63"
        },
        {
            "Issue Age": 26,
            "5000": "$22.84",
            "10000": "$42.20"
        },
        {
            "Issue Age": 27,
            "5000": "$22.66",
            "10000": "$41.85"
        },
        {
            "Issue Age": 28,
            "5000": "$22.53",
            "10000": "$41.59"
        },
        {
            "Issue Age": 29,
            "5000": "$22.45",
            "10000": "$41.41"
        },
        {
            "Issue Age": 30,
            "5000": "$22.40",
            "10000": "$41.33"
        },
        {
            "Issue Age": 31,
            "5000": "$22.49",
            "10000": "$41.50"
        },
        {
            "Issue Age": 32,
            "5000": "$22.58",
            "10000": "$41.67"
        },
        {
            "Issue Age": 33,
            "5000": "$22.71",
            "10000": "$41.93"
        },
        {
            "Issue Age": 34,
            "5000": "$22.79",
            "10000": "$42.11"
        },
        {
            "Issue Age": 35,
            "5000": "$23.88",
            "10000": "$44.28"
        },
        {
            "Issue Age": 36,
            "5000": "$24.62",
            "10000": "$45.76"
        },
        {
            "Issue Age": 37,
            "5000": "$25.36",
            "10000": "$47.24"
        },
        {
            "Issue Age": 38,
            "5000": "$26.19",
            "10000": "$48.89"
        },
        {
            "Issue Age": 39,
            "5000": "$27.06",
            "10000": "$50.63"
        },
        {
            "Issue Age": 40,
            "5000": "$27.93",
            "10000": "$52.37"
        },
        {
            "Issue Age": 41,
            "5000": "$28.62",
            "10000": "$53.77"
        },
        {
            "Issue Age": 42,
            "5000": "$29.28",
            "10000": "$55.07"
        },
        {
            "Issue Age": 43,
            "5000": "$30.02",
            "10000": "$56.55"
        },
        {
            "Issue Age": 44,
            "5000": "$30.80",
            "10000": "$58.12"
        },
        {
            "Issue Age": 45,
            "2500": "$$17.51",
            "5000": "$31.54",
            "10000": "$59.60",
            "15000": "$87.65",
            "20000": "$115.71",
            "25000": "$143.77"
        },
        {
            "Issue Age": "46",
            "2500": "$17.68",
            "5000": "$31.89",
            "10000": "$60.29",
            "15000": "$88.70",
            "20000": "$117.10",
            "25000": "$145.51"
        },
        {
            "Issue Age": "47",
            "2500": "$17.88",
            "5000": "$32.28",
            "10000": "$61.07",
            "15000": "$89.87",
            "20000": "$118.67",
            "25000": "$147.47"
        },
        {
            "Issue Age": "48",
            "2500": "$18.05",
            "5000": "$32.63",
            "10000": "$61.77",
            "15000": "$90.92",
            "20000": "$120.06",
            "25000": "$149.21"
        },
        {
            "Issue Age": "49",
            "2500": "$18.23",
            "5000": "$32.97",
            "10000": "$62.47",
            "15000": "$91.96",
            "20000": "$121.45",
            "25000": "$150.95"
        },
        {
            "Issue Age": "50",
            "2500": "$18.40",
            "5000": "$33.32",
            "10000": "$63.16",
            "15000": "$93.00",
            "20000": "$122.84",
            "25000": "$152.69"
        },
        {
            "Issue Age": "51",
            "2500": "$18.62",
            "5000": "$33.76",
            "10000": "$64.03",
            "15000": "$94.31",
            "20000": "$124.58",
            "25000": "$154.86"
        },
        {
            "Issue Age": "52",
            "2500": "$18.77",
            "5000": "$34.06",
            "10000": "$64.64",
            "15000": "$95.22",
            "20000": "$125.80",
            "25000": "$156.38"
        },
        {
            "Issue Age": "53",
            "2500": "$18.97",
            "5000": "$34.45",
            "10000": "$65.42",
            "15000": "$96.40",
            "20000": "$127.37",
            "25000": "$158.34"
        },
        {
            "Issue Age": "54",
            "2500": "$19.16",
            "5000": "$34.84",
            "10000": "$66.21",
            "15000": "$97.57",
            "20000": "$128.93",
            "25000": "$160.30"
        },
        {
            "Issue Age": "55",
            "2500": "$19.31",
            "5000": "$35.15",
            "10000": "$66.82",
            "15000": "$98.48",
            "20000": "$130.15",
            "25000": "$161.82"
        },
        {
            "Issue Age": "56",
            "2500": "$19.92",
            "5000": "$36.37",
            "10000": "$69.25",
            "15000": "$102.14",
            "20000": "$135.02",
            "25000": "$167.91"
        },
        {
            "Issue Age": "57",
            "2500": "$20.53",
            "5000": "$37.58",
            "10000": "$71.69",
            "15000": "$105.79",
            "20000": "$139.90",
            "25000": "$174.00"
        },
        {
            "Issue Age": "58",
            "2500": "$21.18",
            "5000": "$38.89",
            "10000": "$74.30",
            "15000": "$109.71",
            "20000": "$145.12",
            "25000": "$180.53"
        },
        {
            "Issue Age": "59",
            "2500": "$21.84",
            "5000": "$40.19",
            "10000": "$76.91",
            "15000": "$113.62",
            "20000": "$150.34",
            "25000": "$187.05"
        },
        {
            "Issue Age": "60",
            "2500": "$22.58",
            "5000": "$41.67",
            "10000": "$79.87",
            "15000": "$118.06",
            "20000": "$156.25",
            "25000": "$194.45"
        },
        {
            "Issue Age": "61",
            "2500": "$23.40",
            "5000": "$43.33",
            "10000": "$83.17",
            "15000": "$123.02",
            "20000": "$162.86",
            "25000": "$202.71"
        },
        {
            "Issue Age": "62",
            "2500": "$24.29",
            "5000": "$45.11",
            "10000": "$86.74",
            "15000": "$128.37",
            "20000": "$170.00",
            "25000": "$211.63"
        },
        {
            "Issue Age": "63",
            "2500": "$25.25",
            "5000": "$47.02",
            "10000": "$90.57",
            "15000": "$134.11",
            "20000": "$177.65",
            "25000": "$221.20"
        },
        {
            "Issue Age": "64",
            "2500": "$26.23",
            "5000": "$48.98",
            "10000": "$94.48",
            "15000": "$139.98",
            "20000": "$185.48",
            "25000": "$230.99"
        },
        {
            "Issue Age": "65",
            "2500": "$27.32",
            "5000": "$51.16",
            "10000": "$98.83",
            "15000": "$146.51",
            "20000": "$194.18",
            "25000": "$241.86"
        },
        {
            "Issue Age": "66",
            "2500": "$28.75",
            "5000": "$54.03",
            "10000": "$104.57",
            "15000": "$155.12",
            "20000": "$205.67",
            "25000": "$256.22"
        },
        {
            "Issue Age": "67",
            "2500": "$30.25",
            "5000": "$57.03",
            "10000": "$110.58",
            "15000": "$164.13",
            "20000": "$217.67",
            "25000": "$271.22"
        },
        {
            "Issue Age": "68",
            "2500": "$31.89",
            "5000": "$60.29",
            "10000": "$117.10",
            "15000": "$173.91",
            "20000": "$230.72",
            "25000": "$287.54"
        },
        {
            "Issue Age": "69",
            "2500": "$33.65",
            "5000": "$63.81",
            "10000": "$124.15",
            "15000": "$184.48",
            "20000": "$244.82",
            "25000": "$305.15"
        },
        {
            "Issue Age": "70",
            "2500": "$35.56",
            "5000": "$67.64",
            "10000": "$131.81",
            "15000": "$195.97",
            "20000": "$260.13",
            "25000": "$324.29"
        },
        {
            "Issue Age": "71",
            "2500": "$37.63",
            "5000": "$71.78",
            "10000": "$140.07",
            "15000": "$208.37",
            "20000": "$276.66",
            "25000": "$44.96"
        },
        {
            "Issue Age": "72",
            "2500": "$39.87",
            "5000": "$76.26",
            "10000": "$149.03",
            "15000": "$221.81",
            "20000": "$294.58",
            "25000": "$367.36"
        },
        {
            "Issue Age": "73",
            "2500": "$42.24",
            "5000": "$81.00",
            "10000": "$158.51",
            "15000": "$236.03",
            "20000": "$313.55",
            "25000": "$391.07"
        },
        {
            "Issue Age": "74",
            "2500": "$44.81",
            "5000": "$86.13",
            "10000": "$168.78",
            "15000": "$251.43",
            "20000": "$334.08",
            "25000": "$416.73"
        },
        {
            "Issue Age": "75",
            "2500": "$47.50",
            "5000": "$91.52",
            "10000": "$179.57",
            "15000": "$267.61",
            "20000": "$355.66",
            "25000": "$443.70"
        },
        {
            "Issue Age": "76",
            "2500": "$50.72",
            "5000": "$97.96",
            "10000": "$192.44",
            "15000": "$286.93",
            "20000": "$381.41",
            "25000": "$475.89"
        },
        {
            "Issue Age": "77",
            "2500": "$54.51",
            "5000": "$105.53",
            "10000": "$207.58",
            "15000": "$309.63",
            "20000": "$411.68",
            "25000": "$513.74"
        },
        {
            "Issue Age": "78",
            "2500": "$58.29",
            "5000": "$113.10",
            "10000": "$222.72",
            "15000": "$332.34",
            "20000": "$441.96",
            "25000": "$551.58"
        },
        {
            "Issue Age": "79",
            "2500": "$62.12",
            "5000": "$120.76",
            "10000": "$238.03",
            "15000": "$355.31",
            "20000": "$472.58",
            "25000": "$589.86"
        },
        {
            "Issue Age": "80",
            "2500": "$62.12",
            "5000": "$120.76",
            "10000": "$238.03",
            "15000": "$355.31",
            "20000": "$472.58",
            "25000": "$589.86"
        },
    ],
    "female": [{
            "Issue Age": "25",
            "5000": "$19.66",
            "10000": "$35.84"
        },
        {
            "Issue Age": "26",
            "5000": "$19.71",
            "10000": "$35.93"
        },
        {
            "Issue Age": "27",
            "5000": "$19.75",
            "10000": "$36.02"
        },
        {
            "Issue Age": "28",
            "5000": "$19.84",
            "10000": "$36.19"
        },
        {
            "Issue Age": "29",
            "5000": "$19.88",
            "10000": "$36.28"
        },
        {
            "Issue Age": "30",
            "5000": "$19.97",
            "10000": "$36.45"
        },
        {
            "Issue Age": "31",
            "5000": "$20.05",
            "10000": "$36.63"
        },
        {
            "Issue Age": "32",
            "5000": "$20.14",
            "10000": "$36.80"
        },
        {
            "Issue Age": "33",
            "5000": "$20.31",
            "10000": "$37.15"
        },
        {
            "Issue Age": "34",
            "5000": "$20.45",
            "10000": "$37.14"
        },
        {
            "Issue Age": "35",
            "5000": "$31.32",
            "10000": "$44.28"
        },
        {
            "Issue Age": "36",
            "5000": "$21.92",
            "10000": "$40.37"
        },
        {
            "Issue Age": "37",
            "5000": "$22.53",
            "10000": "$41.59"
        },
        {
            "Issue Age": "38",
            "5000": "$23.14",
            "10000": "$42.80"
        },
        {
            "Issue Age": "39",
            "5000": "$23.79",
            "10000": "$44.11"
        },
        {
            "Issue Age": "40",
            "5000": "$24.49",
            "10000": "$45.50"
        },
        {
            "Issue Age": "41",
            "5000": "$24.93",
            "10000": "$46.37"
        },
        {
            "Issue Age": "42",
            "5000": "$45.25",
            "10000": "$47.42"
        },
        {
            "Issue Age": "43",
            "5000": "$25.97",
            "10000": "$48.46"
        },
        {
            "Issue Age": "44",
            "5000": "$26.49",
            "10000": "$49.50"
        },
        {
            "Issue Age": "45",
            "2500": "$15.29",
            "5000": "$27.10",
            "10000": "$50.72",
            "15000": "$74.34",
            "20000": "$97.96",
            "25000": "$121.58"
        },
        {
            "Issue Age": "46",
            "2500": "$15.33",
            "5000": "$27.19",
            "10000": "$50.90",
            "15000": "$74.60",
            "20000": "$98.31",
            "25000": "$122.02"
        },
        {
            "Issue Age": "47",
            "2500": "$15.40",
            "5000": "$27.32",
            "10000": "$51.16",
            "15000": "$74.99",
            "20000": "$98.83",
            "25000": "$122.67"
        },
        {
            "Issue Age": "48",
            "2500": "$15.44",
            "5000": "$27.41",
            "10000": "$51.33",
            "15000": "$75.26",
            "20000": "$99.18",
            "25000": "$123.11"
        },
        {
            "Issue Age": "49",
            "2500": "$15.53",
            "5000": "$27.58",
            "10000": "$51.68",
            "15000": "$75.78",
            "20000": "$99.88",
            "25000": "$123.98"
        },
        {
            "Issue Age": "50",
            "2500": "$15.55",
            "5000": "$27.62",
            "10000": "$51.77",
            "15000": "$75.91",
            "20000": "$100.05",
            "25000": "$124.19"
        },
        {
            "Issue Age": "51",
            "2500": "$15.66",
            "5000": "$27.84",
            "10000": "$52.20",
            "15000": "$76.56",
            "20000": "$100.92",
            "25000": "$125.28"
        },
        {
            "Issue Age": "52",
            "2500": "$15.70",
            "5000": "$27.93",
            "10000": "$52.37",
            "15000": "$76.82",
            "20000": "$101.27",
            "25000": "$125.72"
        },
        {
            "Issue Age": "53",
            "2500": "$15.79",
            "5000": "$28.10",
            "10000": "$52.72",
            "15000": "$77.34",
            "20000": "$101.96",
            "25000": "$126.59"
        },
        {
            "Issue Age": "54",
            "2500": "$15.86",
            "5000": "$28.23",
            "10000": "$52.98",
            "15000": "$77.73",
            "20000": "$102.49",
            "25000": "$127.24"
        },
        {
            "Issue Age": "55",
            "2500": "$15.90",
            "5000": "$28.32",
            "10000": "$53.16",
            "15000": "$78.00",
            "20000": "$102.83",
            "25000": "$127.67"
        },
        {
            "Issue Age": "56",
            "2500": "$16.29",
            "5000": "$29.10",
            "10000": "$54.72",
            "15000": "$80.34",
            "20000": "$105.97",
            "25000": "$131.59"
        },
        {
            "Issue Age": "57",
            "2500": "$16.70",
            "5000": "$29.93",
            "10000": "$56.38",
            "15000": "$82.82",
            "20000": "$109.27",
            "25000": "$135.72"
        },
        {
            "Issue Age": "58",
            "2500": "$17.14",
            "5000": "$30.80",
            "10000": "$58.12",
            "15000": "$85.43",
            "20000": "$112.75",
            "25000": "$140.07"
        },
        {
            "Issue Age": "59",
            "2500": "$17.62",
            "5000": "$31.76",
            "10000": "$60.03",
            "15000": "$88.31",
            "20000": "$116.58",
            "25000": "$144.86"
        },
        {
            "Issue Age": "60",
            "2500": "$18.12",
            "5000": "$32.76",
            "10000": "$62.03",
            "15000": "$91.31",
            "20000": "$120.58",
            "25000": "$149.86"
        },
        {
            "Issue Age": "61",
            "2500": "$18.75",
            "5000": "$34.02",
            "10000": "$64.55",
            "15000": "$95.09",
            "20000": "$125.63",
            "25000": "$156.17"
        },
        {
            "Issue Age": "62",
            "2500": "$19.38",
            "5000": "$35.28",
            "10000": "$67.08",
            "15000": "$98.88",
            "20000": "$130.67",
            "25000": "$162.47"
        },
        {
            "Issue Age": "63",
            "2500": "$20.08",
            "5000": "$36.67",
            "10000": "$69.86",
            "15000": "$103.05",
            "20000": "$136.24",
            "25000": "$169.43"
        },
        {
            "Issue Age": "64",
            "2500": "$20.84",
            "5000": "$38.19",
            "10000": "$72.91",
            "15000": "$107.62",
            "20000": "$142.33",
            "25000": "$177.05"
        },
        {
            "Issue Age": "65",
            "2500": "$21.62",
            "5000": "$39.76",
            "10000": "$76.04",
            "15000": "$112.32",
            "20000": "$148.60",
            "25000": "$184.88"
        },
        {
            "Issue Age": "66",
            "2500": "$22.77",
            "5000": "$42.06",
            "10000": "$80.65",
            "15000": "$119.23",
            "20000": "$157.82",
            "25000": "$196.40"
        },
        {
            "Issue Age": "67",
            "2500": "$23.99",
            "5000": "$44.50",
            "10000": "$85.52",
            "15000": "$126.54",
            "20000": "$167.56",
            "25000": "$208.58"
        },
        {
            "Issue Age": "68",
            "2500": "$25.32",
            "5000": "$47.15",
            "10000": "$90.83",
            "15000": "$134.50",
            "20000": "$178.18",
            "25000": "$221.85"
        },
        {
            "Issue Age": "69",
            "2500": "$26.77",
            "5000": "$50.07",
            "10000": "$96.66",
            "15000": "$143.25",
            "20000": "$189.83",
            "25000": "$236.42"
        },
        {
            "Issue Age": "70",
            "2500": "$28.36",
            "5000": "$53.24",
            "10000": "$103.01",
            "15000": "$152.77",
            "20000": "$202.54",
            "25000": "$252.30"
        },
        {
            "Issue Age": "71",
            "2500": "$30.02",
            "5000": "$56.55",
            "10000": "$109.62",
            "15000": "$162.69",
            "20000": "$215.76",
            "25000": "$268.83"
        },
        {
            "Issue Age": "72",
            "2500": "$31.80",
            "5000": "$60.12",
            "10000": "$116.75",
            "15000": "$173.39",
            "20000": "$230.03",
            "25000": "$286.67"
        },
        {
            "Issue Age": "73",
            "2500": "$33.69",
            "5000": "$63.90",
            "10000": "$124.32",
            "15000": "$184.74",
            "20000": "$245.17",
            "25000": "$305.59"
        },
        {
            "Issue Age": "74",
            "2500": "$35.76",
            "5000": "$68.03",
            "10000": "$132.59",
            "15000": "$197.14",
            "20000": "$261.70",
            "25000": "$326.25"
        },
        {
            "Issue Age": "75",
            "2500": "$37.98",
            "5000": "$72.47",
            "10000": "$141.46",
            "15000": "$210.45",
            "20000": "$279.44",
            "25000": "$348.44"
        },
        {
            "Issue Age": "76",
            "2500": "$38.52",
            "5000": "$73.56",
            "10000": "$143.64",
            "15000": "$213.72",
            "20000": "$283.79",
            "25000": "$353.87"
        },
        {
            "Issue Age": "77",
            "2500": "$41.63",
            "5000": "$79.78",
            "10000": "$156.08",
            "15000": "$232.38",
            "20000": "$308.68",
            "25000": "$384.98"
        },
        {
            "Issue Age": "78",
            "2500": "$44.98",
            "5000": "$86.48",
            "10000": "$169.48",
            "15000": "$252.47",
            "20000": "$335.47",
            "25000": "$418.47"
        },
        {
            "Issue Age": "79",
            "2500": "$48.57",
            "5000": "$93.66",
            "10000": "$183.83",
            "15000": "$274.01",
            "20000": "$364.18",
            "25000": "$454.36"
        },
        {
            "Issue Age": "80",
            "2500": "$52.18",
            "5000": "$100.88",
            "10000": "$198.27",
            "15000": "$295.67",
            "20000": "$393.07",
            "25000": "$490.46"
        },
    ]
}];