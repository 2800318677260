var gerberData = [{
  "female": [{
      "Issue Age": 50,
      "5000": "$17.89",
      "10000": "$34.47",
      "15000": "$51.24",
      "20000": "$68.02",
      "25000": "$84.79"
    },
    {
      "Issue Age": 51,
      "5000": "$18.93",
      "10000": "$36.94",
      "15000": "$54.94",
      "20000": "$72.97",
      "25000": "$90.98"
    },
    {
      "Issue Age": 52,
      "5000": "$19.98",
      "10000": "$39.05",
      "15000": "$58.12",
      "20000": "$77.18",
      "25000": "$96.25"
    },
    {
      "Issue Age": 53,
      "5000": "$20.95",
      "10000": "$40.98",
      "15000": "$61.00",
      "20000": "$81.03",
      "25000": "$101.06"
    },
    {
      "Issue Age": 54,
      "5000": "$21.82",
      "10000": "$42.72",
      "15000": "$63.62",
      "20000": "$84.52",
      "25000": "$105.42"
    },
    {
      "Issue Age": 55,
      "5000": "$22.60",
      "10000": "$44.28",
      "15000": "$65.95",
      "20000": "$87.63",
      "25000": "$109.31"
    },
    {
      "Issue Age": 56,
      "5000": "$23.47",
      "10000": "$46.02",
      "15000": "$68.57",
      "20000": "$91.12",
      "25000": "$113.67"
    },
    {
      "Issue Age": 57,
      "5000": "$24.15",
      "10000": "$47.39",
      "15000": "$70.63",
      "20000": "$93.87",
      "25000": "$117.11"
    },
    {
      "Issue Age": 58,
      "5000": "$24.84",
      "10000": "$48.77",
      "15000": "$72.69",
      "20000": "$96.62",
      "25000": "$120.54"
    },
    {
      "Issue Age": 59,
      "5000": "$25.48",
      "10000": "$50.05",
      "15000": "$74.62",
      "20000": "$99.18",
      "25000": "$123.75"
    },
    {
      "Issue Age": 60,
      "5000": "$25.99",
      "10000": "$51.06",
      "15000": "$76.13",
      "20000": "$101.20",
      "25000": "$126.27"
    },
    {
      "Issue Age": 61,
      "5000": "$27.50",
      "10000": "$54.08",
      "15000": "$80.67",
      "20000": "$107.25",
      "25000": "$133.83"
    },
    {
      "Issue Age": 62,
      "5000": "$28.88",
      "10000": "$56.83",
      "15000": "$84.79",
      "20000": "$112.75",
      "25000": "$140.71"
    },
    {
      "Issue Age": 63,
      "5000": "$30.07",
      "10000": "$59.22",
      "15000": "$88.37",
      "20000": "$117.52",
      "25000": "$146.67"
    },
    {
      "Issue Age": 64,
      "5000": "$30.98",
      "10000": "$61.05",
      "15000": "$91.12",
      "20000": "$121.18",
      "25000": "$151.25"
    },
    {
      "Issue Age": 65,
      "5000": "$31.76",
      "10000": "$62.61",
      "15000": "$93.45",
      "20000": "$124.30",
      "25000": "$155.15"
    },
    {
      "Issue Age": 66,
      "5000": "$33.37",
      "10000": "$65.82",
      "15000": "$98.27",
      "20000": "$130.72",
      "25000": "$163.17"
    },
    {
      "Issue Age": 67,
      "5000": "$34.70",
      "10000": "$68.48",
      "15000": "$102.25",
      "20000": "$136.03",
      "25000": "$169.81"
    },
    {
      "Issue Age": 68,
      "5000": "$35.93",
      "10000": "$70.95",
      "15000": "$105.97",
      "20000": "$140.98",
      "25000": "$176.00"
    },
    {
      "Issue Age": 69,
      "5000": "$37.13",
      "10000": "$73.33",
      "15000": "$109.54",
      "20000": "$145.75",
      "25000": "$181.96"
    },
    {
      "Issue Age": 70,
      "5000": "$38.23",
      "10000": "$75.53",
      "15000": "$112.84",
      "20000": "$150.15",
      "25000": "$187.46"
    },
    {
      "Issue Age": 71,
      "5000": "$41.89",
      "10000": "$82.87",
      "15000": "$123.84",
      "20000": "$164.82",
      "25000": "$205.79"
    },
    {
      "Issue Age": 72,
      "5000": "$45.38",
      "10000": "$89.83",
      "15000": "$134.29",
      "20000": "$178.75",
      "25000": "$223.21"
    },
    {
      "Issue Age": 73,
      "5000": "$48.72",
      "10000": "$96.53",
      "15000": "$144.33",
      "20000": "$192.13",
      "25000": "$239.94"
    },
    {
      "Issue Age": 74,
      "5000": "$51.79",
      "10000": "$102.67",
      "15000": "$153.54",
      "20000": "$204.42",
      "25000": "$255.29"
    },
    {
      "Issue Age": 75,
      "5000": "$54.54",
      "10000": "$108.17",
      "15000": "$161.79",
      "20000": "$215.42",
      "25000": "$255.29"
    },
    {
      "Issue Age": 76,
      "5000": "$62.33",
      "10000": "$123.75",
      "15000": "$185.17",
      "20000": "$246.59",
      "25000": "$308.00"
    },
    {
      "Issue Age": 77,
      "5000": "$69.67",
      "10000": "$138.42",
      "15000": "$207.17",
      "20000": "$275.92",
      "25000": "$344.67"
    },
    {
      "Issue Age": 78,
      "5000": "$76.54",
      "10000": "$152.17",
      "15000": "$227.79",
      "20000": "$303.42",
      "25000": "$379.04"
    },
    {
      "Issue Age": 79,
      "5000": "$82.96",
      "10000": "$165.00",
      "15000": "$247.04",
      "20000": "$329.09",
      "25000": "$411.13"
    },
    {
      "Issue Age": 80,
      "5000": "$88.64",
      "10000": "$176.37",
      "15000": "$264.09",
      "20000": "$351.82",
      "25000": "$439.55"
    }
  ],
  "male": [{ // Male
      "Issue Age": 50,
      "5000": "$22.50",
      "10000": "$44.09",
      "15000": "$65.68",
      "20000": "$87.27",
      "25000": "$108.86"
    },
    {
      "Issue Age": 51,
      "5000": "$24.15",
      "10000": "$47.39",
      "15000": "$70.63",
      "20000": "$93.87",
      "25000": "$117.11"
    },
    {
      "Issue Age": 52,
      "5000": "$25.67",
      "10000": "$50.42",
      "15000": "$75.17",
      "20000": "$99.92",
      "25000": "$124.67"
    },
    {
      "Issue Age": 53,
      "5000": "$27.09",
      "10000": "$53.26",
      "15000": "$79.43",
      "20000": "$105.60",
      "25000": "$131.77"
    },
    {
      "Issue Age": 54,
      "5000": "$28.33",
      "10000": "$55.73",
      "15000": "$83.14",
      "20000": "$110.55",
      "25000": "$137.96"
    },
    {
      "Issue Age": 55,
      "5000": "$29.43",
      "10000": "$57.93",
      "15000": "$86.44",
      "20000": "$114.95",
      "25000": "$143.46"
    },
    {
      "Issue Age": 56,
      "5000": "$30.25",
      "10000": "$59.58",
      "15000": "$88.92",
      "20000": "$118.25",
      "25000": "$147.58"
    },
    {
      "Issue Age": 57,
      "5000": "$30.98",
      "10000": "$61.05",
      "15000": "$91.12",
      "20000": "$121.18",
      "25000": "$151.25"
    },
    {
      "Issue Age": 58,
      "5000": "$31.63",
      "10000": "$62.33",
      "15000": "$93.04",
      "20000": "$123.75",
      "25000": "$154.46"
    },
    {
      "Issue Age": 59,
      "5000": "$32.08",
      "10000": "$63.25",
      "15000": "$94.42",
      "20000": "$125.58",
      "25000": "$156.75"
    },
    {
      "Issue Age": 60,
      "5000": "$32.40",
      "10000": "$63.89",
      "15000": "$95.38",
      "20000": "$126.87",
      "25000": "$158.36"
    },
    {
      "Issue Age": 61,
      "5000": "$34.74",
      "10000": "$68.57",
      "15000": "$102.39",
      "20000": "$136.22",
      "25000": "$170.04"
    },
    {
      "Issue Age": 62,
      "5000": "$36.94",
      "10000": "$72.97",
      "15000": "$108.99",
      "20000": "$145.02",
      "25000": "$181.04"
    },
    {
      "Issue Age": 63,
      "5000": "$39.05",
      "10000": "$77.18",
      "15000": "$115.32",
      "20000": "$153.45",
      "25000": "$191.58"
    },
    {
      "Issue Age": 64,
      "5000": "$41.07",
      "10000": "$81.22",
      "15000": "$121.37",
      "20000": "$161.52",
      "25000": "$201.67"
    },
    {
      "Issue Age": 65,
      "5000": "$43.04",
      "10000": "$85.16",
      "15000": "$127.28",
      "20000": "$169.40",
      "25000": "$211.52"
    },
    {
      "Issue Age": 66,
      "5000": "$44.78",
      "10000": "$88.64",
      "15000": "$132.51",
      "20000": "$176.31",
      "25000": "$220.23"
    },
    {
      "Issue Age": 67,
      "5000": "$46.29",
      "10000": "$91.67",
      "15000": "$137.04",
      "20000": "$182.42",
      "25000": "$227.79"
    },
    {
      "Issue Age": 68,
      "5000": "$47.71",
      "10000": "$94.51",
      "15000": "$141.31",
      "20000": "$188.10",
      "25000": "$234.90"
    },
    {
      "Issue Age": 69,
      "5000": "$48.95",
      "10000": "$96.98",
      "15000": "$145.02",
      "20000": "$193.05",
      "25000": "$241.09"
    },
    {
      "Issue Age": 70,
      "5000": "$50.05",
      "10000": "$99.18",
      "15000": "$148.32",
      "20000": "$197.45",
      "25000": "$246.59"
    },
    {
      "Issue Age": 71,
      "5000": "$54.54",
      "10000": "$108.17",
      "15000": "$161.79",
      "20000": "$215.42",
      "25000": "$269.04"
    },
    {
      "Issue Age": 72,
      "5000": "$58.94",
      "10000": "$116.97",
      "15000": "$174.99",
      "20000": "$233.02",
      "25000": "$291.04"
    },
    {
      "Issue Age": 73,
      "5000": "$63.07",
      "10000": "$125.22",
      "15000": "$187.37",
      "20000": "$249.52",
      "25000": "$311.67"
    },
    {
      "Issue Age": 74,
      "5000": "$66.92",
      "10000": "$132.92",
      "15000": "$198.92",
      "20000": "$264.92",
      "25000": "$330.92"
    },
    {
      "Issue Age": 75,
      "5000": "$70.22",
      "10000": "$139.52",
      "15000": "$208.82",
      "20000": "$278.12",
      "25000": "$347.42"
    },
    {
      "Issue Age": 76,
      "5000": "$81.90",
      "10000": "$162.89",
      "15000": "$243.88",
      "20000": "$324.87",
      "25000": "$405.86"
    },
    {
      "Issue Age": 77,
      "5000": "$93.13",
      "10000": "$185.35",
      "15000": "$277.57",
      "20000": "$369.79",
      "25000": "$462.00"
    },
    {
      "Issue Age": 78,
      "5000": "$103.90",
      "10000": "$206.89",
      "15000": "$309.88",
      "20000": "$412.87",
      "25000": "$515.86"
    },
    {
      "Issue Age": 79,
      "5000": "$114.22",
      "10000": "$227.52",
      "15000": "$340.82",
      "20000": "$454.12",
      "25000": "$567.42"
    },
    {
      "Issue Age": 80,
      "5000": "$124.12",
      "10000": "$247.32",
      "15000": "$370.52",
      "20000": "$493.72",
      "25000": "$616.92"
    }
  ]
}];