/** Controls the which forms to display and which to hide */

app.controller("flowController", function ($scope, $http, $httpParamSerializer, $location, leadFactory, isValid) {


    // Default form configuration
    $scope.contact = true;
    $scope.displayOtherPerson = false;
    $scope.formSelection = false;
    $scope.columbian = false;
    $scope.kemper = false;
    $scope.gerber = false;

    $scope.isSelfApplicant = function (selfApplicantSelection) {

        if (selfApplicantSelection) {

            $scope.selfApplicant = selfApplicantSelection;
            $scope.displayOtherPerson = false;
            $scope.$parent.sidebarMessage = "A little about you.";

        } else {

            $scope.selfApplicant = false;
            $scope.displayOtherPerson = true;
            $scope.$parent.sidebarMessage = "A little information about your loved one.";

        }
    }

    $scope.formChoice = function (form) {

        $scope.formSelection = false;

        switch (form) {
            case 'columbian':
                $scope.columbian = true;
                $scope.$parent.sidebarMessage = "Columbian Application";

                break;
            case 'kemper':
                $scope.kemper = true;
                $scope.$parent.sidebarMessage = "Kemper Application";

                break;
            case 'gerber':
                $scope.gerber = true;
                $scope.$parent.sidebarMessage = "Gerber Application";

                break;
        }
    }

    /** @author Adam Lewis
     * @description Checks if nextStep is true.
     */
    $scope.moveToNextStep = function () {

        console.log("Move To Next Step: " + isValid.getValidation());

        if (isValid.getValidation()) {
            $scope.contact = false;
            $scope.displayOtherPerson = false;
            $scope.formSelection = true;
        }
    }


    /** @author Adam Lewis
     * @description POSTS data.
     */
    $scope.submit = function () {

        var object = {};

        for (var key in $scope.lead) {
            if (typeof $scope.lead[key] === 'object' && $scope.lead[key].hasOwnProperty('$modelValue') && $scope.lead[key].$dirty) { /// Worst stop ever, this needs to be refactored for sure.
                object[$scope.lead[key].$name] = $scope.lead[key].$viewValue; // Dynamic key values.
                leadFactory.setLeadData(object);
            }
        }
debugger
        if (isValid.getValidation()) { // Confirms the form is valid prior to POSTing

            console.log("Submitting...");
            console.log(leadFactory.getLeadData());

			//I did test for my local
			// $http({
                // method: 'POST',
                // url: 'http://localhost:50055/api/Generate/CreateEmbeddedSigningView',
                // data: JSON.stringify(leadFactory.getLeadData()),
            // }).then(function (response) {
                    // alert("Success, data being passed: " + response.data);
                // },
                // function (error) {
                    // alert("Error: " + error.message + " Error Data: " + error.data);
                // });
			
			
             $http({
                 method: 'post',
                url: 'http://insurancewishcenter.com/beta/process/',
                data: leadfactory.getleaddata(),
            }).then(function (response) {
                     alert("success, data being passed: " + response.data);
                 },
                function (error) {
                     alert("error: " + error.message + " error data: " + error.data);
                });

        }
    }

});