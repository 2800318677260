
app.service('priceCalcService', function ($http) {
  this.calculateRate = function (ageOfInsured, amount, gender) {
    
    let policyObj = new Policy();    
    policyObj.ageOfInsured = ageOfInsured;
    policyObj.amount = amount;
    policyObj.gender = gender;
    quoteFrom = policyObj.curPolicy;

    // console.log(quoteFrom);
    return policyObj.policyCheck();
  } // Passing all the data to a Policy class.
});