/**
 * @author Adam
 * @description Used to store the lead object to POST data to specified URL.
 * You would use a factory in this case because, it's a single object we're invoking, we don't need to return the "new" keyword every call.
 */
app.factory('leadFactory', function() {

    var lead = [];

    lead.setLeadData = function(data) {
        lead = data;
    }

    lead.getLeadData = function () {

        return lead;
    }

    return lead;
})

//TODO: Complete the factory set-up for the lead object.