app.directive('age', function (isValid) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {

            let maxLength = 4;
            let currentYear = (new Date()).getFullYear();

            scope.$watch(function () {

                if (ngModelCtrl.$viewValue) { // Prevents console error.
                    if (ngModelCtrl.$viewValue.length === maxLength) { // Once the value is defined, confirm the length of the view.

                        let age = currentYear - parseInt(ngModelCtrl.$viewValue);
                        

                        if(age > 80 || age < 25) {

                            element.addClass('error__input');
                            element.removeClass('ng-valid');
                            element.removeClass('ng-valid-required');
                            isValid.setValidation(false);
                        }
                    }
                }
            });
        }
    };
});