// This is where the Policy Objects will sit.

class Policy {

    constructor(ageOfInsured, amount, gender) {
        this.ageOfInsured = ageOfInsured,
            this.amount = amount,
            this.gender = gender,
            this.curPolicy = {},
            this.curPolicy.name = [],
            this.curPolicy.amount = [],
            this.gerFemale = gerberData.map(f => f.female),
            this.gerMale = gerberData.map(m => m.male),
            this.kempFemale = kemperData.map(f => f.female),
            this.kempMale = kemperData.map(m => m.male),
            this.columFemale = columbianData.map(f => f.female),
            this.columMale = columbianData.map(m => m.male),
            this.gerberObject = { // Constraints
                "min": 5000,
                "max": 25000,
                "ageOfInsured_min": 50,
                "ageOfInsured_max": 80
            },
            this.kemperObject = {
                "min": 2000,
                "max": 25000,
                "ageOfInsured_min": 40,
                "ageOfInsured_max": 80
            },
            this.columbianObject = {
                "min": 2000,
                "max": 25000,
                "ageOfInsured_min": 25,
                "ageOfInsured_max": 80
            };
    }

    policyCheck() {

        if (this.ageOfInsured >= this.kemperObject.ageOfInsured_min && this.ageOfInsured <= this.kemperObject.ageOfInsured_max) {
            this.curPolicy.name.push("kemper");
            this.curPolicy.amount.push(this.kemper());
        }

        if (this.ageOfInsured >= this.gerberObject.ageOfInsured_min && this.ageOfInsured <= this.gerberObject.ageOfInsured_max) {
            this.curPolicy.name.push("gerber");
            this.curPolicy.amount.push(this.gerber());
        }

        if (this.ageOfInsured >= this.columbianObject.ageOfInsured_min && this.ageOfInsured <= this.gerberObject.ageOfInsured_max) {
            this.curPolicy.name.push("columbian");
            this.curPolicy.amount.push(this.columbian());
        }
        return this.curPolicy;
    }

    columbian() { // Columbian Selection - Locks the data range.
        if (this.ageOfInsured === this.columbianObject.ageOfInsured_min || this.ageOfInsured === this.columbianObject.ageOfInsured_min) {

            //Check if the current data is valid for the policy.
            if (this.gender == "male") {
                let index = this.ageOfInsured - this.columbianObject.ageOfInsured_min;

                return this.columMale[0][index][this.amount];
            } else {
                let index = this.ageOfInsured - this.columbianObject.ageOfInsured_min;

                return this.columFemale[0][index][this.amount];
            }
        }

        if (this.gender == "male") {
            let index = this.ageOfInsured - this.columbianObject.ageOfInsured_min;

            if (this.ageOfInsured >= 25 && this.ageOfInsured <= 44) {

                document.getElementById("columbian-min").innerHTML = "5,000";
                document.getElementById("columbian-max").innerHTML = "10,000";
            } else {
                document.getElementById("columbian-min").innerHTML = "2,500";
                document.getElementById("columbian-max").innerHTML = "25,000";
            }

            return this.columMale[0][index][this.amount];
        } else {
            let index = this.ageOfInsured - this.columbianObject.ageOfInsured_min;

            return this.columFemale[0][index][this.amount];
        }
    }

    kemper() { // Kemper selection. - Locks the data range.
        if (this.ageOfInsured === this.kemperObject.ageOfInsured_min || this.ageOfInsured === this.kemperObject.ageOfInsured_min) {

            //Check if the current data is valid for the policy.
            if (this.gender == "male") {
                let index = this.ageOfInsured - this.kemperObject.ageOfInsured_min;

                return this.kempMale[0][index][this.amount];
            } else {
                let index = this.ageOfInsured - this.kemperObject.ageOfInsured_min;
                return this.kempFemale[0][index][this.amount];
            }
        }

        if (this.gender == "male") {
            let index = this.ageOfInsured - this.kemperObject.ageOfInsured_min;

            return this.kempMale[0][index][this.amount];
        } else {
            let index = this.ageOfInsured - this.kemperObject.ageOfInsured_min;

            return this.kempFemale[0][index][this.amount];
        }
    }

    gerber() { // Gerber selection. - Locks the data range.

        if (this.ageOfInsured === this.gerberObject.ageOfInsured_min || this.ageOfInsured === this.gerberObject.ageOfInsured_min) {

            //Check if the current data is valid for the policy.
            if (this.gender == "male") {
                let index = this.ageOfInsured - this.gerberObject.ageOfInsured_min;

                return this.gerMale[0][index][this.amount];
            } else {
                let index = this.ageOfInsured - this.gerberObject.ageOfInsured_min;
                return this.gerFemale[0][index][this.amount];
            }
        }

        if (this.gender == "male") {
            let index = this.ageOfInsured - this.gerberObject.ageOfInsured_min;

            return this.gerMale[0][index][this.amount];
        } else {
            let index = this.ageOfInsured - this.gerberObject.ageOfInsured_min;

            return this.gerFemale[0][index][this.amount];
        }

    }
}