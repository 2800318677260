app.service("isValid", function () {

    var isValid;

    this.setValidation = function (isValid) {
        this.isValid = isValid;
    }

    this.getValidation = function () {
        return this.isValid;
    }
});